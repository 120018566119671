import React, { useEffect, useRef, useState } from "react";
import {
  Platform,
  Pressable,
  RefreshControl,
  ScrollView,
  StyleSheet,
  Text,
  TextInput,
  View,
} from "react-native";
import RemixIcon from "react-native-remix-icon";
import colors from "../../../colors";
import { Card } from "../../components/card";
import { Font } from "../../components/font";
import { Footer } from "../../components/footer";
import { Button, Input } from "../../components/input";
import { TouchableOpacity } from "react-native";
import * as Contacts from "expo-contacts";
import AsyncStorage from "@react-native-async-storage/async-storage";
import axiosInstance from "../../constants/axios-config";
import { apiURIV2 } from "../../constants";
import { isSignedIn } from "../../actions/auth-actions";
import ResponseModal from "../../components/general/modals/ResponseModal";
import { catchAPIErrorText } from "../../utils/error-handler";

export const InviteContact = ({ navigation, route }) => {
  const [contacts, setContacts] = useState([]);
  const [showResponseModal, setShowResponseModal] = React.useState({
    visible: false,
    onRequestClose: () => null,
    bodyText: "",
  });
  const [numberEntered, setNumberEntered] = useState("");
  // const [inValue ,setInValue] = useState(contact.selected)
  const [responseModal, setResponseModal] = React.useState({
    visible: false,
    message: "",
    DismissText: "",
  });
  const closeResponseModal = () => {
    setResponseModal((prev) => ({ ...prev, visible: false }));
  };
  const scrollViewRef = useRef(null);
  const [searchInput, setSearchInput] = useState(""); // New state for search input
  // const [inValue ,setInValue] = useState(contact.selected)
  const [isLoading, setIsLoading] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [pagination, setPagination] = useState({
    total: 0,
    offset: 0,
    limit: 50,
  });
  const [allContacts, setAllContacts] = useState([]);
  useEffect(() => {
    fetchContacts();
  }, []);

  useEffect(() => {
    if (allContacts.length > 0) {
      paginateContacts(0); // Paginate once contacts are available
    }
  }, [allContacts]); // This ensures pagination happens after contacts are fetched

  useEffect(() => {
    fetchInvitedContacts();
  }, []);

  const fetchContacts = async () => {
    const { status } = await Contacts.requestPermissionsAsync();
    if (status === "granted") {
      setIsLoading(true);
      const res = await Contacts.getContactsAsync() // Fetch all contacts at once
        .catch(console.log)
        .finally(() => {
          setIsLoading(false);
        });
      if (res?.data?.length > 0) {
        setAllContacts(
          res?.data
            ?.map((contact) => ({
              ...contact,
              selected: false,
            }))
            .sort((a, b) => a.name.localeCompare(b.name))
        );
        setPagination((prev) => ({
          ...prev,
          total: res?.total,
        }));
      }
    }
  };
  const paginateContacts = (offset) => {
    const start = offset;
    const end = offset + pagination.limit;
    setContacts(allContacts.slice(start, end)); // Display contacts for the current page
    setPagination((prev) => ({ ...prev, offset }));
  };
  const fetchInvitedContacts = async () => {
    try {
      const invitedContactIds = await AsyncStorage.getItem("invitedContacts");
      if (invitedContactIds) {
        const updatedContacts = contacts.filter(
          (contact) => !invitedContactIds.includes(contact.id)
        );
        setContacts(updatedContacts);
      }
    } catch (error) {
      console.error("Error fetching invited contacts", error);
    }
  };
  const handleCheckboxChange = (contactId) => {
    setAllContacts((prevContacts) =>
      prevContacts.map((contact) =>
        contact.id === contactId
          ? { ...contact, selected: !contact.selected }
          : contact
      )
    );
  };

  // Handle invite action
  const handleInviteButtonPress = (ManualNumber = false) => {
    const selectedContacts = allContacts.filter((contact) => contact.selected);
    // Call sendInvitation logic here
    ManualNumber
      ? sendInvitation(numberEntered, true)
      : sendInvitation(selectedContacts);
  };

  useEffect(() => {
    if (searchInput) {
      const filtered = allContacts.filter(
        (contact) =>
          contact.name.toLowerCase().includes(searchInput.toLowerCase()) ||
          contact.phoneNumbers?.some((phone) =>
            phone.number
              .replace(/\s/g, "")
              .includes(searchInput.replace(/\s/g, ""))
          )
      );
      setContacts(filtered);
    } else {
      paginateContacts(0);
    }
  }, [searchInput, allContacts]);
  const sendInvitation = async (selectedContacts, ManualNumber = false) => {
    const formattedContacts = ManualNumber
      ? numberEntered
      : selectedContacts.map((contact) => ({
          name: contact.name,
          mobile:
            contact.phoneNumbers && contact.phoneNumbers.length > 0
              ? contact.phoneNumbers[0].number
              : "",
        }));
    const apiEndpoint = apiURIV2 + "/invites";
    const userToken = await isSignedIn();
    const requestOptions = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      },
    };

    try {
      const response = await axiosInstance.post(
        apiEndpoint,
        {
          contacts: ManualNumber
            ? [{ name: "", mobile: numberEntered }]
            : formattedContacts,
        },
        requestOptions
      );
      if (Platform.OS == "web") {
        console.log("done");
        if (response.status === 200) {
          setShowResponseModal({
            visible: true,
            onRequestClose: () => {
              setShowResponseModal({
                visible: false,
                onRequestClose: () => null,
                bodyText: "",
              });
            },
            bodyText: "Invitation sent successfully !",
          });
          setNumberEntered("");
        } else {
          console.error("Error sending invitation 2:", error);
          setShowResponseModal({
            visible: true,
            onRequestClose: () => {
              setShowResponseModal({
                visible: false,
                onRequestClose: () => null,
                bodyText: "",
              });
            },
            bodyText: catchAPIErrorText(
              e,
              "Unknown Error while Sending Invitation"
            ),
          });
        }
      } else if (response.status === 200) {
        const invitedContactIds = selectedContacts.map((contact) => contact.id);
        await AsyncStorage.setItem(
          "invitedContacts",
          JSON.stringify(invitedContactIds)
        );
        const updatedContacts = contacts.filter(
          (contact) => !invitedContactIds.includes(contact.id)
        );
        setContacts(updatedContacts);
        setShowResponseModal({
          visible: true,
          onRequestClose: () => {
            setShowResponseModal({
              visible: false,
              onRequestClose: () => null,
              bodyText: "",
            });
          },
          bodyText: "Invitation sent successfully !",
        });
      }
    } catch (error) {
      console.error("Error sending invitation 2:", error);
      setShowResponseModal({
        visible: true,
        onRequestClose: () => {
          setShowResponseModal({
            visible: false,
            onRequestClose: () => null,
            bodyText: "",
          });
        },
        bodyText: catchAPIErrorText(
          e,
          "Unknown Error while Sending Invitation"
        ),
      });
    }
  };

  const filteredContacts = searchInput
    ? allContacts.filter(
        (contact) =>
          contact.name.toLowerCase().includes(searchInput.toLowerCase()) ||
          contact.phoneNumbers?.some((phone) =>
            phone.number
              .replace(/\s/g, "")
              .includes(searchInput.replace(/\s/g, ""))
          )
      )
    : allContacts.slice(
        pagination.offset,
        pagination.offset + pagination.limit
      );

  const renderContacts = () => {
    if (isLoading) {
      return (
        <Text
          style={{ textAlign: "center", fontSize: 16, paddingVertical: 30 }}
        >
          Loading...
        </Text>
      );
    }

    if (filteredContacts.length === 0) {
      return (
        <>
          {Platform.OS != "web" ? (
            <Text style={{ padding: 10, textAlign: "center" }}>
              No Contacts
            </Text>
          ) : (
            <>
              <View className="flex justify-center items-center my-3">
                <View className=" w-[60%]  mb-4 px-4 lg:px-0 lg:py-2 flex-row bg-white rounded-full shadow-sm">
                  <View className="w-full flex-row justify-between items-center">
                    <TextInput
                      className="w-full  ml-2 p-1"
                      placeholder="Enter the phone number to Invite"
                      placeholderTextColor="#94a3b8"
                      value={numberEntered} // Controlled input
                      // onChangeText={setSearchInput} // Update search input state
                      onChangeText={(text) => {
                        // Allow only numbers
                        const numericInput = text.replace(/[^0-9]/g, ""); // Remove non-numeric characters
                        setNumberEntered(numericInput);
                        // setSearchInput(numericInput); // Update state with numeric value
                      }}
                      returnKeyType="search"
                    />
                  </View>
                </View>
                <TouchableOpacity
                  className={`p-2 ${
                    numberEntered.length > 0 ? "bg-app-e" : "bg-app-b"
                  } rounded shadow-sm z-50`}
                  onPress={() => handleInviteButtonPress(true)}
                  disabled={numberEntered == ""}
                >
                  <Text
                    className={`text-center ${
                      numberEntered.length > 0 ? "text-white" : "text-black"
                    }`}
                  >
                    Invite Number
                  </Text>
                </TouchableOpacity>
              </View>
            </>
          )}
        </>
      );
    }

    return filteredContacts.map((contact) => (
      <View
        key={contact.id}
        className="py-4 border-b border-gray-200 flex-row items-center w-full"
      >
        <View className="flex-1">
          <Text>{contact.name}</Text>
          <Text>{contact?.phoneNumbers?.[0]?.number}</Text>
        </View>
        <View className="w-8">
          <Input
            value={contact.selected}
            setValue={() => handleCheckboxChange(contact.id)}
            type="checkbox"
          />
        </View>
      </View>
    ));
  };

  // useEffect(() => {
  //   if (!searchInput) {
  //     setContacts(allContacts.slice(pagination.offset, pagination.offset + pagination.limit));
  //   }
  // }, [allContacts, pagination.offset, searchInput]);
  // useEffect(() => {
  //   console.log("Filtered Contacts:", filteredContacts);
  // }, [filteredContacts]);

  // useEffect(() => {
  //   console.log("All Contacts:", allContacts);
  // }, [allContacts]);

  return (
    <View className="relative flex-1 bg-app-e lg:bg-gray-50">
      <ScrollView
        ref={scrollViewRef}
        className="p-4 rounded-t-2xl bg-gray-50"
        contentContainerStyle={{
          flexGrow: 1,
          justifyContent: "center",
          alignItems: "center",
        }}
        refreshControl={
          Platform.OS === "web" ? null : (
            <RefreshControl
              refreshing={isRefreshing}
              onRefresh={() => {
                setIsRefreshing(true);
                fetchContacts({
                  limit: pagination.limit,
                  offset: pagination.offset,
                });
                setTimeout(() => setIsRefreshing(false), 1000);
              }}
            />
          )
        }
      >
        <ResponseModal
          visible={responseModal.visible}
          bodyText={responseModal.message}
          onRequestClose={closeResponseModal}
        ></ResponseModal>
        <View className=" max-w-screen-lg w-full mx-auto">
          <View className="lg:my-5 hidden lg:flex">
            <Font className="text-lg font-semibold text-app-d">
              Your Contacts
            </Font>
          </View>
          <View className="flex-col lg:flex-row max-w-screen-lg w-full">
            <View className="w-full lg:w-4/6 p-0 lg:pr-4">
              <View className={`w-full mb-4 lg:mb-8 justify-center rounded-lg`}>
                <View className="flex-row pb-4 w-full justify-center md:justify-end">
                  <Button
                    label="Invited contacts"
                    type="primary"
                    className="min-w-max px-8 w-full md:w-60"
                    onPress={() => navigation.navigate("contacted")}
                  />
                </View>
                {Platform.OS != "web" && (
                  <View className="flex justify-center items-center">
                    <View className=" w-full lg:w-3/5 mb-4 px-4 lg:px-0 lg:py-1 flex-row bg-white rounded-full shadow-sm">
                      <View className="w-full flex-row justify-between items-center">
                        <TextInput
                          className="w-full lg:w-1/2 ml-2 "
                          placeholder="...Search by Name or phone"
                          placeholderTextColor="#94a3b8"
                          value={searchInput} // Controlled input
                          onChangeText={setSearchInput} // Update search input state
                          returnKeyType="search"
                        />
                        <Pressable
                          // onPress={getCustomersToInvite}
                          className="w-8 h-8 -ml-6 lg:mr-2 lg:ml-0 justify-center items-center"
                        >
                          <RemixIcon
                            name="ri-search-line"
                            color={"#94a3b8"}
                            size={20}
                          />
                          {/* <SVGLoader
                      src={search}
                      height={25}
                      width={25}
                      webHeight={"20em"}
                      webWidth={"auto"}
                    /> */}
                        </Pressable>
                      </View>
                    </View>
                  </View>
                )}
                <View
                  className={`bg-white w-full items-center border border-gray-200 rounded-lg shadow-lg shadow-gray-300 px-3`}
                >
                  <View className=" w-full lg:w-4/6 p-0 lg:pr-4">
                    <View style={styles.content}>
                      <View>{renderContacts()}</View>
                    </View>
                    {!searchInput &&
                      (Platform.OS != "web" ? (
                        <View className="flex-row pb-4 w-full justify-center lg:justify-end">
                          <Button
                            label="Load More"
                            type="primary"
                            className="min-w-max px-8 lg:w-60 my-1"
                            onPress={() => {
                              paginateContacts(
                                pagination.offset + pagination.limit
                              );
                              if (scrollViewRef.current) {
                                scrollViewRef.current.scrollTo({
                                  y: 0,
                                  animated: true,
                                });
                              }
                            }}
                            disabled={
                              pagination.offset + pagination.limit >=
                              pagination.total
                            }
                          />
                        </View>
                      ) : (
                        <></>
                      ))}
                  </View>
                </View>
              </View>
            </View>
            <View className=" flex-1 w-full  lg:w-2/6 p-0 lg:pl-4">
              <Card title="SMBEx">
                <View className="w-full flex-1 p-4 pt-0">
                  <Font>
                    We collaboratively administrate turnkey channels where
                    kingdom businesses can become virtual retailers. We provide
                    scalable metrics, proactive e-services and objective metrics
                    for small businesses to thrive in any economic environment.
                  </Font>
                </View>
              </Card>
              <Card title="Find us there">
                <View className="flex-1 w-full p-4 pt-0">
                  <View className=" w-full flex-1 flex-row items-center">
                    <View className="h-16 w-16 items-center justify-center">
                      <RemixIcon
                        name="phone-fill"
                        size={40}
                        color={colors.app.d1}
                      ></RemixIcon>
                    </View>
                    <View>
                      <Font>Phone number</Font>
                      <Font className="text-app-e">1 877 963-5797</Font>
                    </View>
                  </View>
                  <View className="flex-row items-center">
                    <View className="h-16 w-16 items-center justify-center">
                      <RemixIcon
                        name="global-fill"
                        size={40}
                        color={colors.app.d1}
                      ></RemixIcon>
                    </View>
                    <View>
                      <Font>Website</Font>
                      <Font className="text-app-e">www.SMBEx.com</Font>
                    </View>
                  </View>
                  <View className="flex-row items-center">
                    <View className="h-16 w-16 items-center justify-center">
                      <RemixIcon
                        name="mail-fill"
                        size={40}
                        color={colors.app.d1}
                      ></RemixIcon>
                    </View>
                    <View>
                      <Font>Email Address</Font>
                      <Font className="text-app-e">office@SMBEx.com</Font>
                    </View>
                  </View>
                </View>
              </Card>
            </View>
          </View>
          <Footer></Footer>
        </View>
      </ScrollView>
      <View className="mb-4 absolute self-center bottom-0">
        {contacts.length === 0 ? null : (
          <TouchableOpacity
            className="p-2 w-60 bg-app-e rounded shadow-sm z-50"
            onPress={handleInviteButtonPress}
            // disabled={!contacts.some((contact) => contact.selected)}
            style={{
              backgroundColor: "red",
              justifyContent: "center",
              borderRadius: 5,
              padding: 7,
            }}
          >
            <Text className="text-center text-white">Invite Contact</Text>
          </TouchableOpacity>
        )}
      </View>
      {showResponseModal?.visible && (
        <ResponseModal
          visible={showResponseModal?.visible}
          onRequestClose={showResponseModal?.onRequestClose}
          bodyText={showResponseModal?.bodyText}
          dismissText={showResponseModal?.dismissText}
        />
      )}
      {/* {loading && (
        <ActivityIndicator
          color={colors.app.e}
          size={"large"}
          style={{ position: "absolute", alignSelf: "center", top: 50 }}
        />
      )} */}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 20,
  },
  title: {
    fontSize: 18,
    // fontWeight: 700,
    marginBottom: 10,
  },
  contactContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 10,
  },
  checkbox: {
    marginLeft: 10,
    display: "block",
    borderColor: "red",
    // flex:1
  },
  contactName: {
    fontSize: 16,
  },
  contactNumber: {
    marginLeft: 10,
  },
  content: {
    marginLeft: 20,
  },
  warning: {
    color: "orange",
    textAlign: "center",
    marginRight: 20,
    fontSize: 13,
  },
  space: {
    marginHorizontal: 200,
  },
});
