import {
  Modal,
  Pressable,
  ScrollView,
  StyleSheet,
  Text,
  TouchableWithoutFeedback,
  View,
} from "react-native";
import React from "react";
import RemixIcon from "react-native-remix-icon";
import { Font } from "../../font";

const OptionsModal = ({
  isMenuVisible,
  closeMenu,
  group_role,
  setIsDeleteModal,
  onPressForFirstOption,
}) => {
  console.log(onPressForFirstOption, "onPressForFirstOption");

  return (
    <Modal
      visible={isMenuVisible}
      transparent={true}
      onRequestClose={closeMenu}
    >
      <TouchableWithoutFeedback onPress={closeMenu}>
        <View
          style={{
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
          }}
          className="w-full h-full justify-center items-center bg-black/50"
        >
          <TouchableWithoutFeedback>
            <View
              style={{
                // position: "absolute",
                // left: buttonPosition.left,
                // top: buttonPosition.top,
                backgroundColor: "white",
                padding: 10,
                borderRadius: 10,
                // width: 120,
                shadowColor: "#000",
                shadowOffset: {
                  width: 0,
                  height: 2,
                },
                shadowOpacity: 0.25,
                shadowRadius: 3.84,
                elevation: 5,
              }}
            >
              <ScrollView>
                <Pressable
                  onPress={onPressForFirstOption}
                  className=" pr-0 flex-1 flex-row items-center bg-app-a rounded-lg shadow-sm"
                >
                  <View className="flex-row items-center">
                    <View>
                      <RemixIcon
                        name={
                          group_role === "admin" ? "group-line" : "admin-line"
                        }
                        // color="#EF1211FF"
                        size="16"
                      ></RemixIcon>
                    </View>
                    <Font className="pl-2 p-2 text-base">
                      {group_role === "admin" ? "Make Member" : "Make Admin"}
                    </Font>
                  </View>
                </Pressable>
                <View
                  style={{
                    borderWidth: 0.4,
                    marginVertical: 4,
                  }}
                ></View>

                <Pressable
                  onPress={() => {
                    if (group_role === "owner") {
                      return;
                    } else {
                      setIsDeleteModal(true);
                    }
                    closeMenu();
                  }}
                  className="pr-0 flex-1 flex-row items-center bg-app-a rounded-lg shadow-sm"
                >
                  <View className="flex-row items-center">
                    <View>
                      <RemixIcon
                        name="delete-bin-line"
                        color="#EF1211FF"
                        size={16}
                      />
                    </View>
                    <Font className="text-app-e pl-2 p-2 text-base">
                      Remove
                    </Font>
                  </View>
                </Pressable>
              </ScrollView>
            </View>
          </TouchableWithoutFeedback>
        </View>
      </TouchableWithoutFeedback>
    </Modal>
  );
};

export default OptionsModal;

const styles = StyleSheet.create({});
