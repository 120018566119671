import React from "react";
import {
  Image,
  View,
  Pressable,
  Platform,
  ImageBackground,
} from "react-native";
import { Font, A } from "../../components/font";
import { Button, Input } from "../../components/input";
import { ScrollView } from "react-native";
import { AuthContext } from "../../contexts/auth-context";
import { logIn } from "../../actions/auth-actions";
import ResponseModal from "../../components/general/modals/ResponseModal";
import { useNotifications } from "../../utils/useNotifications";

import GoogleSigninFunction from "./googleSignin";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { catchAPIErrorText } from "../../utils/error-handler";
import { useSharedContext } from "../../contexts/shared-context";

export default function Signin({ navigation, route }) {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [remember, setRemember] = React.useState(false);
  const [isSigningIn, setIsSigningIn] = React.useState(false);
  const [isGoogleSigningIn, setIsGoogleSigningIn] = React.useState();
  const [showAPIResponse, setShowAPIResponse] = React.useState({
    visible: false,
    onRequestClose: () => null,
    bodyText: "",
  });
  const { user, setUser } = React.useContext(AuthContext);
  const [userInfo, setUserInfo] = React.useState();
  const { registerForPushNotificationsAsync } = useNotifications();
  const { setTemporaryData } = useSharedContext();
  const signIn = async () => {
    try {
      if (!email) {
        return setShowAPIResponse({
          visible: true,
          onRequestClose: () => {
            setShowAPIResponse({
              visible: false,
              onRequestClose: () => null,
              bodyText: "",
            });
          },
          bodyText: "Invalid credentials",
          dismissText: "Okay",
        });
      }
      setIsSigningIn(true);
      const res = await logIn({
        email: email,
        password: password,
        remember: remember,
        navigation: navigation,
      });
      console.log(res, "res signIN ");

      if (
        res.status == 403 &&
        res?.data?.data?.verification_entity == "phone"
      ) {
        if (res.data?.data?.otp)
          setTemporaryData({ mobileSMSOTP: res.data?.data?.otp });
        navigation.replace("verifyOtp", {
          hash: res.data?.data?.hash,
          phoneNumber: res?.data?.data?.phone,
        });
      } else if (
        res.status == 401 &&
        res?.data?.data?.verification_entity == "email"
      ) {
        navigation.replace("confirm-email-sent");
      } else if (res?.status === 200) {
        if (!res?.data?.data) {
          throw new Error({ response: res });
        }

        setUser(res?.data?.data);
        const routes = ((route.params?.rdr || "") + "").split("/");
        let params;
        if (routes?.length > 1) {
          routes.forEach((rn) => {
            if (!rn) return;
            if (!params) params = { screen: rn };
            else if (!params?.params) {
              params.params = { screen: rn };
            } else {
              params.params.params = { screen: rn };
            }
          });
        }
        navigation.replace("app", params);
        registerForPushNotificationsAsync();
      } else if (res?.status) {
        setShowAPIResponse({
          visible: true,
          onRequestClose: () => {
            setShowAPIResponse({
              visible: false,
              onRequestClose: () => null,
              bodyText: "",
            });
          },
          bodyText: catchAPIErrorText({ response: res }),
          dismissText: "Okay",
        });
      }
    } catch (error) {
      console.log("signin error:", error);
      setShowAPIResponse({
        visible: true,
        onRequestClose: () => {
          setShowAPIResponse({
            visible: false,
            onRequestClose: () => null,
            bodyText: "",
          });
        },
        bodyText: error?.message,
      });
    } finally {
      setIsSigningIn(false);
    }
  };

  return (
    <View className="w-full h-full bg-gray-50 flex-row">
      <View className="h-full w-full lg:w-1/2 items-center justify-center">
        <ScrollView
          className="w-full"
          contentContainerStyle={{
            flexGrow: 1,
            justifyContent: "flex-start",
            alignItems: "center",
          }}
          keyboardShouldPersistTaps="always"
        >
          <View
            className={
              "w-80 items-center py-16 " +
              (Platform.OS === "web"
                ? "py-8 px-10 md:px-0 w-full md:w-80"
                : "basis-0")
            }
          >
            <Pressable
              // onPress={() => navigation.replace("app")}
              className="mb-8 items-center"
            >
              <Image
                source={require("../../../assets/smbex-lo.png")}
                className="w-36 h-12 my-2"
              />
              <Font className="text-app-e">Vendor</Font>
            </Pressable>
            <View className="w-full my-2">
              <Font className="text-xl text-app-d" weight={500}>
                Sign in
              </Font>
            </View>
            {Platform.OS == "web" ? (
              <GoogleOAuthProvider clientId="1049029176568-9m667iv9lb1fokt3h6vb0urnfg9m9r6q.apps.googleusercontent.com">
                <GoogleSigninFunction
                  setUser={setUser}
                  setUserInfo={setUserInfo}
                  navigation={navigation}
                />
              </GoogleOAuthProvider>
            ) : (
              <GoogleSigninFunction
                setUser={setUser}
                setUserInfo={setUserInfo}
                navigation={navigation}
              />
            )}
            <View className="w-full h-4 items-center justify-center mb-2">
              <View className="w-full h-[1px] bg-gray-200 my-2"></View>
              <View className="absolute bg-gray-50 py-1 px-2">
                <Font>or</Font>
              </View>
            </View>
            <View className="w-full mb-2">
              <Input
                type="email"
                value={email}
                setValue={setEmail}
                label="Email"
                placeholder="user@example.com"
                validation={true}
              ></Input>
            </View>
            <View className="w-full mb-2">
              <Input
                type="password"
                value={password}
                setValue={setPassword}
                label="Password"
                placeholder="************"
                validation={true}
                onSubmitEditing={signIn}
              ></Input>
            </View>

            <View className="w-full mb-2">
              <Input
                type="checkbox"
                value={remember}
                setValue={setRemember}
                label="Remember me"
              ></Input>
            </View>
            <View className="w-full mb-2 my-2">
              <Button
                type="primary"
                label="Sign in"
                onPress={signIn}
                waitState={isSigningIn}
                loaderWithText="Please Wait"
              ></Button>
            </View>
            <View className="w-full my-4">
              <A
                href="forgot-password"
                label="Forgot password?"
                className="font-semibold text-app-e text-sm text-center"
              />
            </View>
            <View className="w-full h-[1px] bg-gray-200 my-2"></View>
            <View className="w-full my-2">
              <Font className="text-center mb-2">
                Don&rsquo;t have an account?
              </Font>
              <A
                href="signup"
                label="Sign up"
                className="font-semibold text-app-e text-sm text-center"
              />
            </View>
          </View>
        </ScrollView>
      </View>
      <View className="h-full w-1/2 hidden lg:flex bg-app-d">
        <ImageBackground
          className="flex-1"
          source={require("../../../assets/side-background-image.jpg")}
          resizeMode="stretch"
          alt="side-background-home"
        />
      </View>
      <ResponseModal
        visible={showAPIResponse.visible}
        onRequestClose={showAPIResponse.onRequestClose}
        bodyText={showAPIResponse.bodyText}
        dismissText={showAPIResponse?.dismissText}
      />
    </View>
  );
}
