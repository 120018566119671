import React, { useEffect, useState } from "react";
import {
  Pressable,
  View,
  TouchableOpacity,
  Modal,
  Platform,
} from "react-native";
import { getStats, getRecentActivites } from "../../services/api";
import { Font } from "../../components/font";
import { Footer } from "../../components/footer";
import { Card } from "../../components/general/cards/card";

import { ScrollView } from "react-native";
import { TextInput } from "react-native-gesture-handler";
import RemixIcon from "react-native-remix-icon";
import colors from "../../../colors";
import SVGLoader from "../../utils/SVGLoader";

import rightArrow from "../../../assets/right-arrow.svg";
import {
  getGroupMembers,
  MakeAdminApi,
  MakeMemberApi,
  RemoveMemberApi,
  sendInviteToEmail,
} from "../../actions/auth-actions";
import { TouchableWithoutFeedback } from "react-native";
import { PleaseWaitModal } from "../../components/modal";
import ResponseModal from "../../components/general/modals/ResponseModal";
import ConfirmModal from "../../components/general/modals/ConfirmModal";
import OptionsModal from "../../components/general/modals/OptionsModal";

export default function GroupMembers({
  MembersLists,
  navigation,
  route,
  groupId,
}) {
  const [isRefreshList, setRefreshList] = React.useState(true);
  const [emailText, setEmailText] = useState("");
  const checkRefreshListforMembers = () => {
    setRefreshList(!isRefreshList);
  };
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [IsMember, setIsMember] = useState();
  const [IsMemberName, setIsMemberName] = useState();
  const [isPleaseWaitModal, setIsPleaseWaitModal] = useState(false);
  const [responseModal, setResponseModal] = useState({
    visible: false,
    message: "",
    DismissText: "",
  });
  const [deleteModal, setIsDeleteModal] = useState(false);
  const closeDeleteModal = () => {
    setIsDeleteModal(false);
  };

  const [isVisible, setIsVisible] = useState(false);
  const [MembersList, setMembersList] = useState(MembersLists);

  const closeResponseModal = () => {
    setResponseModal((prev) => ({ ...prev, visible: false }));
  };

  const closeMenu = () => {
    setIsMenuVisible(false);
  };

  const sendInviteEmail = async () => {
    try {
      setIsPleaseWaitModal(true);
      const res = await sendInviteToEmail(emailText);
      console.log(res.status, "res status for email invites");
      console.log(res, "res status for email invites");

      if (res.status == 200) {
        setEmailText("");
        setIsPleaseWaitModal(false);
        setResponseModal({
          visible: true,
          message: "Invitation sent Successfully !!!",
          DismissText: "Dismiss",
        });
        checkRefreshListforMembers();
      } else {
        setIsPleaseWaitModal(false);
        setResponseModal({
          visible: true,
          message: "Something went wrong !!!",
          DismissText: "Dismiss",
        });
      }
    } catch (error) {
      setIsPleaseWaitModal(false);
    }
  };

  const RemoveMember = async () => {
    try {
      setIsDeleteModal(false);
      setIsPleaseWaitModal(true);
      let res = await RemoveMemberApi(groupId, IsMember);
      if (res.status === 204) {
        setIsPleaseWaitModal(false);
        setResponseModal({
          visible: true,
          message: IsMemberName + " Removed !!!",
          DismissText: "Dismiss",
        });
        setMembersList((prevList) =>
          prevList.filter((member) => member.id !== IsMember)
        );
        // GetMembers();
        console.log(res.data, "remove member success");
      } else {
        setIsPleaseWaitModal(false);
        console.log(res, "remove member error");
      }
    } catch (error) {
      setIsPleaseWaitModal(false);
      console.log("Error Getting remove member : ", error.response, error);
    }
  };

  const GetMembers = async () => {
    try {
      let res = await getGroupMembers(groupId);
      if (res.status === 200) {
        setMembersList(res.data.data);
      }
    } catch (error) {
      console.log("Error Getting Stats : ", error.response);
    }
  };

  const MakeAdmin = async () => {
    try {
      setIsPleaseWaitModal(true);
      let res = await MakeAdminApi(groupId, IsMember);
      console.log(res, "make admin check");
      if (res.status === 200) {
        setIsPleaseWaitModal(false);
        setResponseModal({
          visible: true,
          message: IsMemberName + " is now Admin !!!",
          DismissText: "Dismiss",
        });
        GetMembers();
        console.log(res.data, "make admin success");
      } else {
        setIsPleaseWaitModal(false);
        console.log(res, "make admin error");
      }
    } catch (error) {
      setIsPleaseWaitModal(false);
      console.log("Error Getting Stats : ", error.response, error);
    }
  };
  const MakeMember = async () => {
    try {
      setIsPleaseWaitModal(true);
      let res = await MakeMemberApi(groupId, IsMember);
      console.log(res, "make member check");
      if (res.status === 200) {
        setIsPleaseWaitModal(false);
        setResponseModal({
          visible: true,
          message: IsMemberName + " is now Member !!!",
          DismissText: "Dismiss",
        });
        GetMembers();
        console.log(res.data, "make member success");
      } else {
        setIsPleaseWaitModal(false);
        console.log(res, "make member error");
      }
    } catch (error) {
      setIsPleaseWaitModal(false);
      console.log("Error Getting Stats : ", error.response, error);
    }
  };
  useEffect(() => {
    GetMembers();
  }, [isRefreshList]);
  return (
    <ScrollView
      className="rounded-t-2xl p-4 bg-gray-50"
      contentContainerStyle={{
        flexGrow: 1,
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <ConfirmModal
        visible={deleteModal}
        onCancel={closeDeleteModal}
        onConfirm={RemoveMember}
        confirmText={"Remove"}
        bodyText={"Are you sure you want to Remove the Member ?"}
        BodyComponent={false}
      ></ConfirmModal>
      {isPleaseWaitModal && <PleaseWaitModal showLoader={true} />}
      <ResponseModal
        visible={responseModal.visible}
        bodyText={responseModal.message}
        onRequestClose={closeResponseModal}
      ></ResponseModal>
      <View className="max-w-screen-lg w-full h-full">
        <View className="max-w-screen-lg w-full flex-col justify-center items-center">
          {/* ------------------------Group Members Tab - section----------------------------------- */}
          <>
            <View className=" w-full flex-col item-center">
              <View className="lg:p-4 lg:flex-row justify-center item-center lg:bg-app-a lg:rounded-lg">
                <View className="flex-col lg:flex-row flex flex-1">
                  <TouchableOpacity
                    className="px-4 flex-row justify-between flex-1 w-full bg-white border border-gray-200 rounded-lg py-2 mb-2 lg:mb-auto shadow-lg shadow-gray-300"
                    onPress={() =>
                      navigation.navigate("group-join-requests", {
                        groupId: groupId,
                      })
                    }
                  >
                    <View className="flex-row justify-start items-center">
                      <View className="flex justify-center items-center">
                        <RemixIcon
                          name="ri-user-fill"
                          size={16}
                          color={colors.app.g4}
                          className="mr-2 lg:mr-4 w-4 h-4 lg:w-6 lg:h-6 flex-shrink-0"
                        />
                      </View>

                      <Font className="lg:text-lg text-base font-medium">
                        Join Requests
                      </Font>
                    </View>
                    <Pressable
                      className="w-8 h-8 flex justify-center items-center"
                      onPress={() => navigation.navigate("group-join-requests")}
                    >
                      <View className="flex justify-center items-center">
                        <RemixIcon
                          name="ri-checkbox-blank-circle-fill"
                          size={16}
                          color={colors.app.h4}
                          className="mr-1 lg:mr-4 w-4 h-4 lg:w-4 lg:h-4 flex-shrink-0"
                        />
                      </View>
                    </Pressable>
                  </TouchableOpacity>
                  <View className="hidden lg:flex lg:w-4" />
                  <TouchableOpacity
                    className="px-4 flex-row justify-between w-full flex-1 bg-white border border-gray-200 rounded-lg py-2 mb-2 lg:mb-auto shadow-lg shadow-gray-300"
                    onPress={() =>
                      navigation.navigate("add-member", {
                        groupId,
                        checkRefreshListforMembers,
                      })
                    }
                  >
                    <View className="flex-row justify-start items-center">
                      <View className="flex justify-center items-center">
                        <RemixIcon
                          name="ri-group-fill"
                          size={16}
                          color={colors.app.g4}
                          className="mr-2 lg:mr-4 w-4 h-4 lg:w-6 lg:h-6 flex-shrink-0"
                        />
                      </View>
                      <Font className="lg:text-lg text-base font-medium">
                        Add / Invite
                      </Font>
                    </View>
                    <View className="flex-row justify-center items-center">
                      <Pressable
                        className="w-8 h-8"
                        onPress={() =>
                          navigation.navigate("add-member", {
                            groupId,
                            checkRefreshListforMembers,
                          })
                        }
                      >
                        <SVGLoader
                          src={rightArrow}
                          height={30}
                          width={30}
                          webHeight={"40em"}
                          webWidth={"auto"}
                        />
                      </Pressable>
                    </View>
                  </TouchableOpacity>
                </View>
                <Card className="flex-1 lg:hidden">
                  <View className="w-full p-4 flex-col">
                    <View className="w-full mb-3 pb-2 flex-row item-center border-b-2 border-b-gray-200">
                      <View className="flex justify-center items-center">
                        <RemixIcon
                          name="ri-mail-fill"
                          size={16}
                          color={colors.app.g4}
                          className="mr-2 lg:mr-4 w-4 h-4 lg:w-6 lg:h-6 flex-shrink-0"
                        />
                      </View>
                      <Font className="lg:text-lg text-base font font-bold self-center">
                        Invite via email
                      </Font>
                    </View>
                    <View className="">
                      <View className="p-2 flex-row bg-white border border-gray-200 rounded-full shadow-sm">
                        <TextInput
                          className="w-full ml-2"
                          placeholder="...Search by e mail"
                          placeholderTextColor="#94a3b8"
                          type="text"
                          value={emailText}
                          onChangeText={(e) => setEmailText(e)}
                        />
                      </View>
                      <Pressable
                        onPress={() => sendInviteEmail()}
                        className="mt-2 flex-row justify-end item-center w-full"
                      >
                        <Font className="lg:text-lg text-sm text-app-e font-semibold">
                          Invite
                        </Font>
                      </Pressable>
                    </View>
                  </View>
                </Card>
              </View>
              <View className="w-full mt-2 p-2 pb-0 lg:mt-6 flex-row justify-center item-center">
                <View className="px-10 py-2 flex-row justify-center item-center bg-app-a rounded-tl-lg rounded-tr-lg">
                  <Font className="text-sm">{MembersList.length} members</Font>
                  {/* <View className="flex justify-center items-center">
                    <RemixIcon
                      name="ri-checkbox-blank-circle-fill"
                      size={4}
                      color={colors.app.i4}
                      className="mr-2 ml-2 mt-1.5 lg:mt-0 w-2 h-2 flex-shrink-0 justify-center items-center"
                    />
                  </View> */}
                  {/* <Font className="text-sm">members</Font> */}
                </View>
              </View>
              <View className="p-2 w-full flex-col justify-center items-center bg-app-a rounded-lg border-t border-t-gray-300">
                {MembersList?.map((member) => (
                  <Pressable
                    onPress={() => {
                      if (member?.group_role == "owner") {
                        return;
                      }
                      setIsMemberName(
                        member.user.first_name + " " + member.user.last_name
                      );
                      setIsMenuVisible(true);
                      setIsMember(member?.id);
                    }}
                    className="p-2 my-2 mb-3 w-full flex-row justify-between items-center lg:border-b lg:border-b-gray-300"
                    key={member?.id}
                  >
                    <View>
                      <Font className="font-bold text-base">
                        {member.user.first_name + " " + member.user.last_name}
                      </Font>
                      <Font>{member.user.email}</Font>
                      <Font>{member.user.phone}</Font>
                    </View>

                    {(member.group_role == "owner" ||
                      member.group_role === "admin") && (
                      <View className="flex-row justify-center items-center">
                        <View
                          className={`px-2 py-1  flex justify-center items-center rounded-lg ${
                            member.group_role === "owner"
                              ? "bg-red-200 border-2 border-app-e mr-8 lg:mr-12"
                              : "bg-green-200 border-2 border-app-g mr-2"
                          }`}
                        >
                          {member.group_role == "owner" && (
                            <Font className="text-xs text-app-e">Owner</Font>
                          )}
                          {member.group_role === "admin" && (
                            <Font className="text-xs text-app-g">Admin</Font>
                          )}
                        </View>

                        <View className="ml-2 flex justify-center items-center">
                          {member.group_role === "admin" && (
                            <RemixIcon
                              name="ri-arrow-down-s-line"
                              size={16}
                              color={colors.app.d4}
                              className="mr-2 lg:mr-4 w-4 h-4 lg:w-6 lg:h-6 flex-shrink-0"
                            />
                          )}
                        </View>
                      </View>
                    )}
                    {isMenuVisible && IsMember == member?.id ? (
                      <OptionsModal
                      onPressForFirstOption={() => {
                          if (member.group_role === "owner") {
                            return;
                          } else if (member.group_role === "admin") {
                            MakeMember();
                          } else {
                            MakeAdmin();
                          }
                          closeMenu();
                        }}
                        isMenuVisible={isMenuVisible}
                        closeMenu={closeMenu}
                        group_role={member.group_role}
                        setIsDeleteModal={setIsDeleteModal}
                      />
                    ) : (
                      // <Modal
                      //   visible={isMenuVisible}
                      //   transparent={true}
                      //   onRequestClose={closeMenu}
                      // >
                      //   <TouchableWithoutFeedback onPress={closeMenu}>
                      //     <View
                      //       style={{
                      //         flex: 1,
                      //         justifyContent: "center",
                      //         alignItems: "center",
                      //       }}
                      //       className="w-full h-full justify-center items-center bg-black/50"
                      //     >
                      //       <TouchableWithoutFeedback>
                      //         <View
                      //           style={{
                      //             // position: "absolute",
                      //             // left: buttonPosition.left,
                      //             // top: buttonPosition.top,
                      //             backgroundColor: "white",
                      //             padding: 10,
                      //             borderRadius: 10,
                      //             // width: 120,
                      //             shadowColor: "#000",
                      //             shadowOffset: {
                      //               width: 0,
                      //               height: 2,
                      //             },
                      //             shadowOpacity: 0.25,
                      //             shadowRadius: 3.84,
                      //             elevation: 5,
                      //           }}
                      //         >
                      //           <ScrollView>
                      //             <Pressable
                      //               onPress={() => {
                      //                 // setIsEditPostModal(true);
                      //                 if (member.group_role === "owner") {
                      //                   return;
                      //                 } else if (
                      //                   member.group_role === "admin"
                      //                 ) {
                      //                   MakeMember();
                      //                 } else {
                      //                   MakeAdmin();
                      //                 }
                      //                 closeMenu();
                      //               }}
                      //               className=" pr-0 flex-1 flex-row items-center bg-app-a rounded-lg shadow-sm"
                      //             >
                      //               <View className="flex-row items-center">
                      //                 <View>
                      //                   <RemixIcon
                      //                     name={
                      //                       member.group_role === "admin"
                      //                         ? "group-line"
                      //                         : "admin-line"
                      //                     }
                      //                     // color="#EF1211FF"
                      //                     size="16"
                      //                   ></RemixIcon>
                      //                 </View>
                      //                 <Font className="pl-2 p-2 text-base">
                      //                   {member.group_role === "admin"
                      //                     ? "Make Member"
                      //                     : "Make Admin"}
                      //                 </Font>
                      //               </View>
                      //             </Pressable>
                      //             <View
                      //               style={{
                      //                 borderWidth: 0.4,
                      //                 marginVertical: 4,
                      //               }}
                      //             ></View>
                      //             <Pressable
                      //               onPress={() => {
                      //                 if (
                      //                   member.group_role === "owner" ||
                      //                   member.group_role === "admin"
                      //                 ) {
                      //                   return;
                      //                 } else {
                      //                   setIsDeleteModal(true);
                      //                 }
                      //                 closeMenu();
                      //               }}
                      //               className="pr-0 flex-1 flex-row items-center bg-app-a rounded-lg shadow-sm"
                      //             >
                      //               <View className="flex-row items-center">
                      //                 <View>
                      //                   <RemixIcon
                      //                     name="delete-bin-line"
                      //                     color="#EF1211FF"
                      //                     size={16}
                      //                   />
                      //                 </View>
                      //                 <Font className="text-app-e pl-2 p-2 text-base">
                      //                   Remove
                      //                 </Font>
                      //               </View>
                      //             </Pressable>
                      //           </ScrollView>
                      //         </View>
                      //       </TouchableWithoutFeedback>
                      //     </View>
                      //   </TouchableWithoutFeedback>
                      // </Modal>
                      <></>
                    )}
                  </Pressable>
                ))}
              </View>
            </View>
          </>
        </View>
        {Platform.OS == "web" ? <></> : <Footer navigation={navigation} />}
      </View>
    </ScrollView>
  );
}
