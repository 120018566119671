import React from "react";
import { TouchableOpacity } from "react-native";
import { View } from "react-native";
import { Modal } from "react-native";
import { Font } from "../../font";
import colors from "../../../../colors";

const ConfirmModal = ({
  visible = false,
  bodyText = "",
  BodyComponent = () => <></>,
  cancelText = "Cancel",
  confirmText = "Confirm",
  onCancel = () => null,
  onConfirm = () => null,
}) => {
  return (
    <Modal
      animationType="fade"
      transparent={true}
      visible={visible}
      onRequestClose={onCancel}
    >
      <View className="flex-1 justify-center items-center bg-black/50">
        <View className="max-w-[340px] bg-app-a border border-app-c min-h-[160px] max-h-full rounded-lg justify-center items-center -mt-12">
          <View className="grow justify-center items-center py-4 px-4">
            {bodyText ? (
              <Font className="text-base">{bodyText}</Font>
            ) : (
              <BodyComponent />
            )}
          </View>
          <View className="w-full h-[1px] bg-gray-200"></View>
          <View className="flex-row w-full">
            <TouchableOpacity
              className="flex-1 py-2 rounded-bl-lg w-full"
              onPress={onCancel}
              style={{ outline: "none" }}
            >
              <Font className="text-base text-center">{cancelText}</Font>
            </TouchableOpacity>
            <View className="h-full w-[1.5px] bg-gray-200"></View>
            <TouchableOpacity
              activeOpacity={0.8}
              underlayColor={colors.app.e}
              className="flex-1 py-2 bg-app-e rounded-br-lg w-full"
              onPress={onConfirm}
              style={{ outline: "none" }}
            >
              <Font className="text-base text-center text-app-a">
                {confirmText}
              </Font>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </Modal>
  );
};

export default ConfirmModal;
