import React from "react";
import { useSharedContext } from "./shared-context";
import { useStripeContext } from "./stripe-context";
import { useVendorFeedContext } from "./vendor-feed-context";
import { useProductsContext } from "./product-context";
import { useOrdersContext } from "./orders-context";
import { useMessagesContext } from "./messages-context";
import { useListingContext } from "./listing-context";
import { useCouponsContext } from "./coupons-context";
import { useBookingsContext } from "./bookings-context";
import { useAuthContext } from "./auth-context";
import { useNotificationContext } from "./notifications-context";

const useAppContextActions = () => {
  const { reset: resetSharedContext } = useSharedContext();
  const { reset: resetSripeContext } = useStripeContext();
  const { resetVendorFeed } = useVendorFeedContext();
  const { resetProductsContext } = useProductsContext();
  const { resetOrdersContext } = useOrdersContext();
  const { resetNotificationContext } = useNotificationContext();
  const { resetMessagesContext } = useMessagesContext();
  const { resetListingContext } = useListingContext();
  const { resetCouponsContext } = useCouponsContext();
  const { resetBookingsContext } = useBookingsContext();
  const { reset: resetAuthContext } = useAuthContext();

  const resetAppContexts = React.useCallback(() => {
    return new Promise((resolve, reject) => {
      try {
        resetSharedContext();
        resetSripeContext();
        resetVendorFeed();
        resetProductsContext();
        resetOrdersContext();
        resetNotificationContext();
        resetMessagesContext();
        resetListingContext();
        resetCouponsContext();
        resetBookingsContext();
        resetAuthContext();
        resolve();
      } catch (e) {
        reject(e);
      }
    });
  }, [resetSharedContext]);

  return { resetAppContexts };
};

export default useAppContextActions;
