import React, { useEffect, useState } from "react";
import {
  ActivityIndicator,
  Image,
  Platform,
  ScrollView,
  View,
} from "react-native";
import { Card } from "../../components/general/cards/card";
import { Font } from "../../components/font";
import { Footer } from "../../components/footer";

import { Widget } from "../../components/widget";
import RenderHTML from "../../components/RenderHTML";
import axiosInstance from "../../constants/axios-config";
import { apiURIV2, mediaURI } from "../../constants";
import colors from "../../../colors";

export default function Post({ route }) {
  const [isLoading, setIsLoading] = useState(false);
  const [blogsDetails, setBlogsDetails] = useState();

  useEffect(() => {
    const getBlogsDetails = async () => {
      try {
        setIsLoading(true);
        const res = await axiosInstance.get(
          apiURIV2 + `/content/blogs/${route.params.slug}`
        );
        setBlogsDetails(res.data.data);
      } catch (error) {
        console.log("new error", error);
      } finally {
        setIsLoading(false);
      }
    };
    if (route.params.slug) {
      getBlogsDetails();
    }
  }, [route.params.slug]);

  return (
    <View className="flex-1 bg-app-e lg:bg-gray-50">
      <View className="flex-1 rounded-t-2xl lg:rounded-none bg-gray-50">
        <ScrollView>
          <View className=" max-w-screen-lg w-full p-4 mx-auto">
            <View className="flex-col lg:flex-row max-w-screen-lg w-full">
              <View
                className=" w-full lg:w-4/6 p-0 lg:pr-4"
                style={{ height: "auto" }}
              >
                {isLoading ? (
                  <ActivityIndicator size={"large"} color={colors.app.e} />
                ) : (
                  <>
                    <View className="w-full h-64 lg:h-96 rounded-lg overflow-hidden mb-4">
                      <Image
                        source={
                          blogsDetails?.cover_image_url
                            ? {
                                uri: `${mediaURI}${blogsDetails?.cover_image_url}`,
                              }
                            : require("../../../assets/banner.jpg")
                        }
                        className="w-full h-full"
                      ></Image>
                    </View>
                    <View className="my-4">
                      <Font className="text-lg font-bold text-app-d leading-6 mb-1">
                        {blogsDetails?.title}
                      </Font>
                      <Font className="font-medium text-app-sm text-app-d1 my-1">
                        {blogsDetails?.published_on}
                      </Font>
                    </View>
                    <Card>
                      <View className="px-4 w-full overflow-hidden py-4">
                        <RenderHTML
                          htmlContent={
                            Platform.OS === "web" ? (
                              <Font>{blogsDetails?.content || ""}</Font>
                            ) : (
                              `${blogsDetails?.content || ""}`
                            )
                          }
                        />
                      </View>
                    </Card>
                  </>
                )}
              </View>
              <View className=" w-full lg:w-2/6 p-0 lg:pl-4 flex-1">
                <Widget></Widget>
              </View>
            </View>
            <Footer></Footer>
          </View>
        </ScrollView>
      </View>
    </View>
  );
}
