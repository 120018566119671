import { useNavigation, useNavigationState } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import React, { useContext, useEffect, useState } from "react";
import * as Notifications from "expo-notifications";
import {
  Image,
  Platform,
  Pressable,
  View,
  Keyboard,
  Modal,
  ActivityIndicator,
  Alert,
  StyleSheet,
} from "react-native";
import * as Linking from "expo-linking";
import { SafeAreaView } from "react-native-safe-area-context";
import RemixIcon from "react-native-remix-icon";
import colors from "../../../colors";
import { Font } from "../../components/font";
import { Navbar } from "../../components/navbar";
import AboutUs from "./about-us";
import Blog from "./blog";
import Bookings from "./bookings";
import ContactUs from "./contact-us";
import Faq from "./faq";
import TermsCondition from "./terms-conditions";
import PrivacyPolicy from "./privacy-policy";
import Home from "./home";
import Search from "./search";
import Messages from "./messages";
import Orders from "./orders";
import Post from "./post";
import Wishlist from "./wishlist";
// const SearchModal =React.lazy(import("./search-modal"));
// const Cart =React.lazy(import("./cart"));
import ListingDetails from "./listing-details";
import ProductDetails from "./product-details";
import Checkout from "./checkout";
import BookingDetails from "./booking-details";
import UpdateProfile from "./update-profile";
import ManageAddresses from "./manage-addresses";
import UpateAddress from "./update-address";
import { InviteContact } from "./invite-contact";
import { Contacted } from "./contacted";
import OrderDetails from "./order-details";
import ListingList from "./listing-list";
import AddListing from "./add-listing";
import UpdateListing from "./update-listing";
import Products from "./products";
import UpdateProduct from "./update-product";
import AddProduct from "./add-product";
// import Settings from "./settings";
import { VendorNotifications } from "./notification";
import Account from "./account";
import { AuthContext } from "../../contexts/auth-context";
import {
  getUserProfile,
  initiateStripe,
  isSignedIn,
  validateStripeStatus,
} from "../../actions/auth-actions";
import { pageTitle } from "../../utils";
import ResponseModal from "../../components/general/modals/ResponseModal";
import { useNotifications } from "../../utils/useNotifications";
import Vendor from "./vendor";
import { useMessagesContext } from "../../contexts/messages-context";
import CouponsList from "./coupons-list";
import AddCoupons from "./add-coupons";
import UpdateCoupons from "./update-coupons";
import ManageSubscription from "./manage-subscription";
import AddAddress from "./addAddress";
// import { getCurrentSubscription } from "../../actions/payment-apis";
import WebView from "react-native-webview";
import { useStripeContext } from "../../contexts/stripe-context";
import ConfirmModal from "../../components/general/modals/ConfirmModal";
import Transactions from "./transactions";
import TopupWithdrawal from "./topup-withdrawal";
import Groups from "./groups";
import AddInviteMember from "./add-member";
import ManageGroups from "./manage-groups";
import GroupJoinRequests from "./group-join-requests";
import VendorFeedComments from "./vendor-feed-comments";
import { useVendorFeedContext } from "../../contexts/vendor-feed-context";
import { catchAPIErrorText } from "../../utils/error-handler";
import axiosInstance from "../../constants/axios-config";
import { apiURIV2 } from "../../constants";
import { useNotificationContext } from "../../contexts/notifications-context";
import Constants from "expo-constants";

const Stack = createNativeStackNavigator();

const tabs = [
  {
    title: "Listings",
    icon: "hotel",
    url: "listings",
  },
  {
    title: "Bookings",
    icon: "calendar",
    url: "bookings",
  },

  {
    title: "Home",
    icon: "image",
    url: "home",
  },
  {
    title: "Messages",
    icon: "message",
    url: "messages",
  },
  {
    title1: "More",
    title2: "Less",
    icon: "menu",
    url: "more",
  },
  {
    title: "Products",
    icon: "archive",
    url: "products",
  },
  {
    title: "Coupons",
    icon: "ri-coupon-2",
    url: "coupons",
  },
  // {
  //   title: "Settings",
  //   icon: "settings",
  //   url: "settings",
  // },
  {
    title: "Orders",
    icon: "shopping-bag",
    url: "orders",
  },
];
const tabsSidebar = [
  {
    title: "Home",
    icon: "dashboard",
    url: "home",
  },
  {
    title: "Listings",
    icon: "hotel",
    url: "listings",
  },
  {
    title: "Bookings",
    icon: "calendar",
    url: "bookings",
  },
  {
    title: "Products",
    icon: "archive",
    url: "products",
  },
  {
    title: "Orders",
    icon: "shopping-bag",
    url: "orders",
  },
  {
    title: "Coupons",
    icon: "ri-coupon-2",
    url: "coupons",
  },
  {
    title: "Messages",
    icon: "message",
    url: "messages",
  },
  // {
  //   title: "Settings",
  //   icon: "settings",
  //   url: "settings",
  // },
];

export default function Root({ navigation }) {
  const { tabStatus, setTabStatus } = useVendorFeedContext();
  const {
    handleNotificationResponse,
    handleNotification,
    registerForPushNotificationsAsync,
  } = useNotifications();
  const { setRedirectChatUserID } = useMessagesContext();
  const {
    stripeStatus,
    setStripeStatus,
    setAllowAddStripeBankAccount,
    validateStripeUserBankAccountStatus,
  } = useStripeContext();
  const { setNotifications, setUnreadCount } = useNotificationContext();

  const [showResponseModal, setShowResponseModal] = React.useState({
    visible: false,
    onRequestClose: () => null,
    bodyText: "",
  });

  const onWebLoad = React.useCallback(() => {
    setStripeStatus((prev) => ({ ...prev, isLoading: false }));
  }, []);

  const onWebLoadStart = React.useCallback(() => {
    setStripeStatus((prev) => ({ ...prev, isLoading: true }));
  }, []);

  const onNavigationStateChange = React.useCallback((event) => {
    const { url } = event;
    // console.log("onNavigationStateChange:", url);
    if (url.endsWith("/return")) {
      setStripeStatus({
        ...stripeStatus,
        isLoading: false,
        isConfirmToValidate: false,
        isUserStripeValidated: true,
        stripeMessage: "",
      });
      validateStripeStatus()
        .then((res) => {
          console.log("res.data:", res.data);
          if (
            res.status === 200 &&
            res.data?.data?.account_reference?.onboarding_status == "completed"
          ) {
            setShowResponseModal({
              visible: true,
              onRequestClose: () => {
                setShowResponseModal({
                  visible: false,
                  onRequestClose: () => null,
                  bodyText: "",
                });
              },
              bodyText: "Your Stripe account has been connected successfully.",
              dismissText: "Okay",
            });
            return res.data?.data;
          } else {
            if (Platform.OS === "web") {
              setShowResponseModal({
                visible: true,
                onRequestClose: () => {
                  if (stripeStatus.isUserStripeValidated) {
                    setStripeStatus({
                      ...stripeStatus,
                      isConfirmToValidate: false,
                      isUserStripeValidated: false,
                      stripeMessage:
                        "Continue where you left off with your Bank Account Setup?",
                    });
                  }
                  setShowResponseModal({
                    visible: false,
                    onRequestClose: () => null,
                    bodyText: "",
                  });
                },
                bodyText: `${"Stripe Onboarding Canceled"}\n\n${
                  res?.data?.message
                    ? res?.data?.message +
                      "\n\nPlease register your Stripe Account to receive the payouts!"
                    : "Please provide your Bank Account details to start accepting payouts from us!"
                }`,
                dismissText: "Okay",
              });
            } else {
              Alert.alert(
                "Stripe Onboarding Canceled",
                `${
                  res?.data?.message
                    ? res?.data?.message +
                      "\n\nPlease provide your Bank Account details to start accepting payouts from us!"
                    : "Please provide your Bank Account details to start accepting payouts from us!"
                }`,
                [
                  {
                    onPress: () => {
                      setStripeStatus({
                        ...stripeStatus,
                        isConfirmToValidate: false,
                        isUserStripeValidated: false,
                        stripeMessage:
                          "Continue where you left off with your Bank Account Setup?",
                      });
                    },
                  },
                ]
              );
            }
          }
        })
        .catch((err) => {
          if (Platform.OS === "web") {
            setShowResponseModal({
              visible: true,
              onRequestClose: () => {
                setStripeStatus({
                  ...stripeStatus,
                  isUserStripeValidated: true,
                  isConfirmToValidate: true,
                  stripeMessage:
                    "Continue where you left off with your Bank Account Setup?",
                });
                setShowResponseModal({
                  visible: false,
                  onRequestClose: () => null,
                  bodyText: "",
                });
              },
              bodyText: `${err?.message || "Error:"}\n\n${err}`,
            });
          } else {
            Alert.alert(err?.message || "Error:", err, [
              {
                onPress: () => {
                  setStripeStatus({
                    ...stripeStatus,
                    isUserStripeValidated: true,
                    isConfirmToValidate: true,
                    stripeMessage:
                      "Continue where you left off with your Bank Account Setup?",
                  });
                },
              },
            ]);
          }
        });
    }
  }, []);

  const onCloseConfirmModal = React.useCallback(() => {
    setAllowAddStripeBankAccount(false);
    setStripeStatus((prev) => ({
      ...prev,
      isLoading: false,
      isUserStripeValidated: true,
      isConfirmToValidate: false,
    }));
  }, []);

  const onConfirmPress = React.useCallback(async () => {
    const initStripeRes = await initiateStripe();
    if (initStripeRes && initStripeRes.status === 200 && initStripeRes.data) {
      const { data } = initStripeRes.data;
      if (
        !data?.account_reference ||
        data?.account_reference?.onboarding_status == "initiated"
      ) {
        if (Platform.OS === "web") {
          return Linking.openURL(data.redirect_link);
        }
        setStripeStatus((prev) => ({
          ...prev,
          isLoading: true,
          isUserStripeValidated: true,
          isConfirmToValidate: true,
          stripeUrl: data.redirect_link,
        }));
      }
    }
  }, []);

  const [gettingUserInfo, setGettingUserInfo] = React.useState(true);

  const routeName = useNavigationState((state) => {
    const routes = state?.routes?.[0]?.state?.routes;
    if (routes) return routes[routes.length - 1].name;
  });

  const [more, setMore] = React.useState(false);

  const { user, setUser } = useContext(AuthContext);

  const [isKeyboardOpen, setIsKeyboardOpen] = useState(false);

  React.useLayoutEffect(() => {
    (async () => {
      try {
        const res = await getUserProfile();
        if (res?.status) {
          if (res.status === 200) {
            setUser(res.data.data);
          }
          if (res.status !== 200) {
            let bodyText = catchAPIErrorText(
              e,
              "Unknown Error while getting user profile"
            );
            setShowResponseModal({
              visible: true,
              onRequestClose: () => {
                setShowResponseModal({
                  visible: false,
                  onRequestClose: () => null,
                  bodyText: "",
                });
                console.log("navigation:", navigation, routeName);

                if (res.status === 401)
                  navigation.replace("signin", { rdr: routeName });
              },
              bodyText: bodyText,
            });
          }
        }
      } catch (error) {
        console.log("error?.code:", error?.code, error);
        if (error?.code) {
          setShowResponseModal({
            visible: true,
            onRequestClose: () => {
              setShowResponseModal({
                visible: false,
                onRequestClose: () => null,
                bodyText: "",
              });
            },
            bodyText:
              (error?.code !== "ECONNABORTED" ? "Network Error: " : "") +
              error?.message,
          });
        }
      } finally {
        setGettingUserInfo(false);
      }
    })();
  }, [setUser, navigation]);

  const keyboardDidShow = () => setIsKeyboardOpen(true);
  const keyboardDidHide = () => setIsKeyboardOpen(false);

  useEffect(() => {
    const showSubscription = Keyboard.addListener(
      "keyboardDidShow",
      keyboardDidShow
    );
    const hideSubscription = Keyboard.addListener(
      "keyboardDidHide",
      keyboardDidHide
    );
    return () => {
      showSubscription.remove();
      hideSubscription.remove();
    };
  }, []);

  // Web receives deep links in this component, but not in routing.js component
  useEffect(() => {
    if (Platform.OS === "web") {
      try {
        // Deep linking event listener
        const handleUrl = ({ url }) => {
          if (!url) {
            return;
          }
          // Process the incoming deep link URL here
          console.log("Received deep link 2:", url);
          let urlStub = url.split("//")[1];
          if (urlStub) {
            let urlStubArray = urlStub.split("/");
            if (Platform.OS === "web") {
              urlStubArray.shift();
            }
            // console.log("Received url urlStubArray for set-new-password :", urlStubArray);
            let urlHash = urlStubArray[1];

            if (urlStubArray[0] === "set-new-password") {
              navigation.navigate("update-password", { urlHash });
            } else if (urlStubArray[0] === "verify-email") {
              navigation.navigate("confirm-email-verify", { urlHash });
            } else if (urlStubArray[0] === "chat") {
              setRedirectChatUserID({
                uid: parseInt(urlStubArray[1].toString()),
                callback: () => navigation.navigate("messages"),
              });
            } else if (urlStubArray[0] === "booking_details") {
              navigation.navigate("booking-details", {
                bid: parseInt(urlStubArray[1].toString()),
              });
            } else if (urlStubArray[0] === "order_details") {
              navigation.navigate("order-details", {
                oid: parseInt(urlStubArray[1].toString()),
              });
            } else if (urlStubArray.includes("stripe")) {
              if (urlStubArray.includes("onboard")) {
                if (urlStubArray.includes("return")) {
                  validateStripeStatus()
                    .then((res) => {
                      console.log("res.data:", res.data);
                      if (
                        res.status === 200 &&
                        res.data?.data?.account_reference?.onboarding_status ==
                          "completed"
                      ) {
                        setShowResponseModal({
                          visible: true,
                          onRequestClose: () => {
                            setShowResponseModal({
                              visible: false,
                              onRequestClose: () => null,
                              bodyText: "",
                            });
                          },
                          bodyText:
                            "Your Stripe account has been connected successfully.",
                          dismissText: "Okay",
                        });
                        return res.data?.data;
                      } else {
                        setShowResponseModal({
                          visible: true,
                          onRequestClose: () => {
                            setShowResponseModal({
                              visible: false,
                              onRequestClose: () => null,
                              bodyText: "",
                            });
                          },
                          bodyText: `${"Stripe Onboarding Canceled"}\n\n${
                            res?.data?.message
                              ? res?.data?.message +
                                "\n\nPlease register your Stripe Account to receive the payouts!"
                              : "Please provide your Bank Account details to start accepting payouts from us!"
                          }`,
                          dismissText: "Okay",
                        });
                      }
                    })
                    .catch((err) => {
                      setShowResponseModal({
                        visible: true,
                        onRequestClose: () => {
                          setStripeStatus({
                            ...stripeStatus,
                            isUserStripeValidated: true,
                            isConfirmToValidate: true,
                            stripeMessage:
                              "Continue where you left off with your Bank Account Setup?",
                          });
                          setShowResponseModal({
                            visible: false,
                            onRequestClose: () => null,
                            bodyText: "",
                          });
                        },
                        bodyText: `${err?.message || "Error:"}\n\n${err}`,
                      });
                    });
                }
              }
            }
          }
        };

        let subscription = Linking.addEventListener("url", handleUrl);
        // Handle initial deep link when the app is opened from a deep link
        Linking.getInitialURL().then((url) => {
          if (url) {
            // Process the initial deep link URL here
            // console.log("Received initial deep link 2:", url);
            handleUrl({ url });
          }
        });
        return () => {
          subscription.remove();
        };
      } catch (err) {
        console.log("error in deep link setup : ", err);
      }
    }
  }, []);

  React.useEffect(() => {
    Notifications.setNotificationHandler({
      handleNotification,
    });
    registerForPushNotificationsAsync().catch((e) => {
      console.log("e:", e);
      setShowResponseModal({
        visible: true,
        onRequestClose: () => {
          setShowResponseModal({
            visible: false,
            onRequestClose: () => null,
            bodyText: "",
          });
        },
        bodyText: e?.message || "Unknown Error while setting up notifications!",
        dismissText: "Dismiss",
      });
    });
    const responseListener =
      Notifications.addNotificationResponseReceivedListener(
        handleNotificationResponse
      );
    return () => {
      if (responseListener)
        Notifications.removeNotificationSubscription(responseListener);
    };
  }, []);

  React.useEffect(() => {
    validateStripeUserBankAccountStatus();
  }, []);

  React.useEffect(() => {
    const getNotifications = () => {
      isSignedIn()
        .then((token) => {
          if (!token) return;
          axiosInstance
            .get(apiURIV2 + "/notifications", {
              params: {
                limit: 20,
              },
              headers: {
                Authorization: `Bearer ${token}`,
              },
            })
            .then((response) => {
              const nots = response.data?.["notifications"];
              let unread = 0;
              nots?.forEach((n) => {
                if (!n.is_read) unread += 1;
              });
              setUnreadCount(unread);
              setNotifications({
                data: nots || [],
              });
            })
            .catch((err) => {
              console.log("notifications error: ", err);
            });
        })
        .catch((err) => {
          console.log("isSignedIn error: ", err);
        });
    };
    getNotifications();
  }, []);

  useEffect(() => {
    setMore((more) => more && !more);
  }, [routeName]);

  // This is handled at getProfile API
  // React.useLayoutEffect(() => {
  //   const getLoginDetails = async () => {
  //     const token = await isSignedIn();
  //     if (!token) {
  //       navigation.replace("signin");
  //     }
  //   };
  //   getLoginDetails();
  // }, [navigation]);

  if (!user && gettingUserInfo) {
    return (
      <View className="flex-1 justify-center items-center">
        {showResponseModal.visible && (
          <ResponseModal
            visible={showResponseModal.visible}
            onRequestClose={showResponseModal.onRequestClose}
            bodyText={showResponseModal.bodyText}
            dismissText={showResponseModal?.dismissText}
          />
        )}
        <Font>Authenticating...</Font>
      </View>
    );
  }

  return (
    <SafeAreaView className="w-full h-full bg-red-600 lg:bg-gray-50 flex-col lg:flex-row">
      <View className="bg-gray-50 hidden lg:flex h-full">
        <View className="h-16 justify-center p-4 bg-white border-b  border-b-gray-200">
          <Image
            source={require("../../../assets/smbex-lo.png")}
            className="w-24 h-12 hidden lg:flex"
          ></Image>
        </View>
        <View className="bg-app-d flex-1 w-64 py-2">
          <View className="w-full h-full flex-col">
            {tabsSidebar?.map((e, i) => (
              <Pressable
                className="w-full items-center justify-start flex-row p-4"
                key={i}
                onPress={() => navigation.replace(e.url)}
              >
                <RemixIcon
                  name={`${e.icon}-${routeName === e.url ? "fill" : "line"}`}
                  color={routeName === e.url ? colors.app.a : colors.app.a1}
                  size="24"
                ></RemixIcon>
                <Font
                  className={`font-medium text-md ml-4 ${
                    routeName === e.url ? "text-app-a" : "text-app-a1"
                  }`}
                >
                  {e.title}
                </Font>
              </Pressable>
            ))}
          </View>
        </View>
      </View>
      <Stack.Navigator screenOptions={{ animation: "none" }}>
        <Stack.Group>
          <Stack.Screen
            name="home"
            component={Home}
            options={{
              title: pageTitle("Home"),
              header: (props) => <Navbar {...props} user={user}></Navbar>,
            }}
          />
          <Stack.Screen
            name="wishlist"
            component={Wishlist}
            options={{
              title: pageTitle("Wishlist"),
              header: (props) => <Navbar {...props} user={user}></Navbar>,
            }}
          />
          <Stack.Screen
            name="listings"
            component={ListingList}
            options={{
              title: pageTitle("Listings"),
              header: (props) => <Navbar {...props} user={user}></Navbar>,
            }}
          />
          <Stack.Screen
            name="listing"
            component={ListingDetails}
            options={{
              title: pageTitle("Listing"),
              header: (props) => <Navbar {...props} user={user}></Navbar>,
            }}
          />
          <Stack.Screen
            name="add-listing"
            component={AddListing}
            options={{
              title: pageTitle("Add Listing"),
              header: (props) => <Navbar {...props} user={user}></Navbar>,
            }}
          />
          <Stack.Screen
            name="update-listing"
            component={UpdateListing}
            options={{
              title: pageTitle("Update Listing"),
              header: (props) => <Navbar {...props} user={user}></Navbar>,
            }}
          />
          <Stack.Screen
            name="bookings"
            component={Bookings}
            options={{
              title: pageTitle("Bookings"),
              header: (props) => <Navbar {...props} user={user}></Navbar>,
            }}
          />
          <Stack.Screen
            name="booking-details"
            component={BookingDetails}
            options={{
              title: pageTitle("Booking Details"),
              header: (props) => <Navbar {...props} user={user}></Navbar>,
            }}
          />
          <Stack.Screen
            name="orders"
            component={Orders}
            options={{
              title: pageTitle("Orders"),
              header: (props) => <Navbar {...props} user={user}></Navbar>,
            }}
          />
          <Stack.Screen
            name="order-details"
            component={OrderDetails}
            options={{
              title: pageTitle("Order Details"),
              header: (props) => <Navbar {...props} user={user}></Navbar>,
            }}
          />
          <Stack.Screen
            name="products"
            component={Products}
            options={{
              title: pageTitle("Products"),
              header: (props) => <Navbar {...props} user={user}></Navbar>,
            }}
          />
          <Stack.Screen
            name="update-product"
            component={UpdateProduct}
            options={{
              title: pageTitle("Update Product"),
              header: (props) => <Navbar {...props} user={user}></Navbar>,
            }}
          />
          <Stack.Screen
            name="add-product"
            component={AddProduct}
            options={{
              title: pageTitle("Add Product"),
              header: (props) => <Navbar {...props} user={user}></Navbar>,
            }}
          />
          <Stack.Screen
            name="product"
            component={ProductDetails}
            options={{
              title: pageTitle("Product"),
              header: (props) => <Navbar {...props} user={user}></Navbar>,
            }}
          />
          <Stack.Screen
            name="coupons"
            component={CouponsList}
            options={{
              title: pageTitle("Coupons"),
              header: (props) => <Navbar {...props} user={user}></Navbar>,
            }}
          />
          <Stack.Screen
            name="add-coupons"
            component={AddCoupons}
            options={{
              title: pageTitle("Add Coupons"),
              header: (props) => <Navbar {...props} user={user}></Navbar>,
            }}
          />
          <Stack.Screen
            name="update-coupons"
            component={UpdateCoupons}
            options={{
              title: pageTitle("Update Coupons"),
              header: (props) => <Navbar {...props} user={user}></Navbar>,
            }}
          />
          <Stack.Screen
            name="messages"
            component={Messages}
            options={{
              title: pageTitle("Messages"),
              header: (props) => <Navbar {...props} user={user}></Navbar>,
            }}
          />
          <Stack.Screen
            name="faq"
            component={Faq}
            options={{
              title: pageTitle("Frequently Asked Questions"),
              header: (props) => <Navbar {...props} user={user}></Navbar>,
            }}
          />
          <Stack.Screen
            name="terms-conditions"
            component={TermsCondition}
            options={{
              title: pageTitle("Terms Of Service"),
              header: (props) => <Navbar {...props} user={user}></Navbar>,
            }}
          />

          <Stack.Screen
            name="privacy-policy"
            component={PrivacyPolicy}
            options={{
              title: pageTitle("Privacy Policy"),
              header: (props) => <Navbar {...props} user={user}></Navbar>,
            }}
          />
          <Stack.Screen
            name="about-us"
            component={AboutUs}
            options={{
              title: pageTitle("About Us"),
              header: (props) => <Navbar {...props} user={user}></Navbar>,
            }}
          />
          <Stack.Screen
            name="contact-us"
            component={ContactUs}
            options={{
              title: pageTitle("Contact Us"),
              header: (props) => <Navbar {...props} user={user}></Navbar>,
            }}
          />
          <Stack.Screen
            name="blog"
            component={Blog}
            options={{
              title: pageTitle("Blog"),
              header: (props) => <Navbar {...props} user={user}></Navbar>,
            }}
          />
          <Stack.Screen
            name="post"
            component={Post}
            options={{
              title: pageTitle("Post"),
              header: (props) => <Navbar {...props} user={user}></Navbar>,
            }}
          />
          <Stack.Screen
            name="search"
            component={Search}
            options={{
              title: pageTitle("Search"),
              header: (props) => <Navbar {...props} user={user}></Navbar>,
            }}
          />
          <Stack.Screen
            name="checkout"
            component={Checkout}
            options={{
              title: pageTitle("Checkout"),
              header: (props) => <Navbar {...props} user={user}></Navbar>,
            }}
          />
          {/* <Stack.Screen
            name="vendor"
            component={Vendor}
            options={{
              title: pageTitle("Vendor Details"),
              header: (props) => <Navbar {...props} user={user}></Navbar>,
            }}
          /> */}
          <Stack.Screen
            name="update-profile"
            component={UpdateProfile}
            options={{
              title: pageTitle("Update Profile"),
              header: (props) => <Navbar {...props} user={user}></Navbar>,
            }}
          />
          <Stack.Screen
            name="manage-subscription"
            component={ManageSubscription}
            options={{
              title: pageTitle("Manage Subscription"),
              header: (props) => <Navbar {...props} user={user}></Navbar>,
            }}
          />
          <Stack.Screen
            name="manage-addresses"
            component={ManageAddresses}
            options={{
              title: pageTitle("Manage Addresses"),
              header: (props) => <Navbar {...props} user={user}></Navbar>,
            }}
          />
          <Stack.Screen
            name="add-address"
            component={AddAddress}
            options={{
              title: pageTitle("Add Address"),
              header: (props) => <Navbar {...props} user={user}></Navbar>,
            }}
          />
          <Stack.Screen
            name="update-address"
            component={UpateAddress}
            options={{
              title: pageTitle("Update Address"),
              header: (props) => <Navbar {...props} user={user}></Navbar>,
            }}
          />
          <Stack.Screen
            name="transactions"
            component={Transactions}
            options={{
              title: pageTitle("Transactions"),
              header: (props) => <Navbar {...props} user={user}></Navbar>,
            }}
          />
          <Stack.Screen
            name="groups"
            component={Groups}
            options={{
              title: pageTitle("Groups"),
              header: (props) => <Navbar {...props} user={user}></Navbar>,
            }}
          />
          <Stack.Screen
            name="manage-groups"
            component={ManageGroups}
            options={{
              title: pageTitle("Manage Groups"),
              header: (props) => <Navbar {...props} user={user}></Navbar>,
            }}
          />
          <Stack.Screen
            name="vendor-feed-comments"
            getId={({ params }) => params.slug}
            options={{
              title: pageTitle("Vendor Feed Comments"),
              // header: (props) => <Navbar {...props} user={user}></Navbar>,
              headerShown: false,
            }}
          >
            {(props) => (
              <VendorFeedComments
                {...props}
                setTabStatus={setTabStatus}
                tabStatus={tabStatus}
              />
            )}
          </Stack.Screen>
          <Stack.Screen
            name="add-member"
            component={AddInviteMember}
            options={{
              title: pageTitle("Add Member"),
              header: (props) => <Navbar {...props} user={user}></Navbar>,
            }}
          />
          <Stack.Screen
            name="group-join-requests"
            component={GroupJoinRequests}
            options={{
              title: pageTitle("Group Join Requests"),
              header: (props) => <Navbar {...props} user={user}></Navbar>,
            }}
          />
          <Stack.Screen
            name="invite-contact"
            component={InviteContact}
            options={{
              title: pageTitle("Invite Contact"),
              header: (props) => <Navbar {...props} user={user}></Navbar>,
            }}
          />
          <Stack.Screen
            name="contacted"
            component={Contacted}
            options={{
              title: pageTitle("Invited Contacts"),
              header: (props) => <Navbar {...props} user={user}></Navbar>,
            }}
          />
          {/* <Stack.Screen
            name="settings"
            component={Settings}
            options={{
              title: pageTitle("Settings"),
              header: (props) => <Navbar {...props} user={user}></Navbar>,
            }}
          /> */}
          <Stack.Screen
            name="vendor"
            component={Vendor}
            options={{
              animation: "slide_from_bottom",
              title: pageTitle("Vendor Details"),
              header: (props) => <Navbar {...props} user={user}></Navbar>,
              // headerShown: false,
            }}
          />
          <Stack.Screen
            name="withdraw"
            component={TopupWithdrawal}
            options={{
              animation: "slide_from_bottom",
              title: pageTitle("Withdraw"),
              header: (props) => <Navbar {...props} user={user}></Navbar>,
            }}
          />
          {/* <Stack.Screen
            name="top-up"
            component={TopupWithdrawal}
            options={{
              animation: "slide_from_bottom",
              title: pageTitle("Top Up"),
              header: (props) => <Navbar {...props} user={user}></Navbar>,
            }}
          /> */}
        </Stack.Group>
        <Stack.Group screenOptions={{ presentation: "transparentModal" }}>
          {/* <Stack.Screen
            name="search-modal"
            component={SearchModal}
            options={{ title: pageTitle("Search"), headerShown: false }}
          /> */}
          <Stack.Screen
            name="notifications"
            component={VendorNotifications}
            options={{
              title: pageTitle("Notifications"),
              header: (props) => <Navbar {...props} user={user}></Navbar>,
            }}
          />
          {/* <Stack.Screen
            name="cart"
            component={Cart}
            options={{ title: pageTitle("Cart"), headerShown: false }}
          /> */}
          <Stack.Screen
            name="account"
            component={Account}
            options={{ title: pageTitle("Account"), headerShown: false }}
          />
        </Stack.Group>
      </Stack.Navigator>
      {/** Bottom Navigation Bar */}
      {isKeyboardOpen && Platform.OS === "android" ? (
        <View />
      ) : (
        <View className="bg-gray-50 flex lg:hidden">
          <View
            className={`bg-app-d rounded-t-2xl  w-full pt-1.5 pb-1 overflow-hidden ${
              more ? "h-max" : "h-20"
            }`}
          >
            <View className="w-full h-36 flex-row flex-wrap justify-between -mt-0.5">
              {tabs?.map((e, i) =>
                e.icon === "image" ? (
                  <View
                    className="w-1/5 h-1/2 items-center justify-center"
                    key={i}
                  >
                    <Pressable
                      className="w-[90%] h-[90%] rounded-2xl items-center justify-center active:bg-app-c2"
                      onPress={() => navigation.replace(e.url)}
                    >
                      <Image
                        source={require("../../../assets/smbex-logoa.png")}
                        className={`w-10 h-10 -mt-1 ${
                          routeName === e.url ? "" : "opacity-80"
                        }`}
                      ></Image>
                      <Font
                        className={`font-medium text-xs text-app-a1 mt-0 ${
                          routeName === e.url ? "text-app-a" : "text-app-a1"
                        }`}
                      >
                        {e.title}
                      </Font>
                    </Pressable>
                  </View>
                ) : e.url == "more" ? (
                  <View
                    className="w-1/5 h-1/2 items-center justify-center"
                    key={i}
                  >
                    <Pressable
                      className="w-[90%] h-[90%] rounded-2xl items-center justify-center active:bg-app-c2"
                      onPress={() => setMore(!more)}
                    >
                      <RemixIcon
                        name={`${e.icon}-${"line"}`}
                        color={more ? colors.app.a : colors.app.a1}
                        size="24"
                      ></RemixIcon>
                      <Font
                        className={`font-medium text-xs mt-2 ${
                          more ? "text-app-a" : "text-app-a1"
                        }`}
                      >
                        {more ? e.title2 : e.title1}
                      </Font>
                    </Pressable>
                  </View>
                ) : (
                  <View
                    className="w-1/5 h-1/2 items-center justify-center"
                    key={i}
                  >
                    <Pressable
                      className="w-[90%] h-[90%] rounded-2xl items-center justify-center active:bg-app-c2"
                      onPress={() => navigation.replace(e.url)}
                    >
                      <RemixIcon
                        name={`${e.icon}-${
                          routeName === e.url ? "fill" : "line"
                        }`}
                        color={
                          routeName === e.url ? colors.app.a : colors.app.a1
                        }
                        size="24"
                      ></RemixIcon>
                      <Font
                        className={`font-medium text-xs mt-2 ${
                          routeName === e.url ? "text-app-a" : "text-app-a1"
                        }`}
                      >
                        {e.title}
                      </Font>
                    </Pressable>
                  </View>
                )
              )}
            </View>
          </View>
        </View>
      )}
      {!stripeStatus.isUserStripeValidated && stripeStatus.stripeMessage && (
        <ConfirmModal
          BodyComponent={""}
          onConfirm={onConfirmPress}
          bodyText={stripeStatus.stripeMessage}
          onCancel={onCloseConfirmModal}
          visible={!stripeStatus.isUserStripeValidated}
          confirmText="Proceed"
          cancelText="Not Now"
        />
      )}
      {stripeStatus.isConfirmToValidate && (
        <Modal
          visible={stripeStatus.isConfirmToValidate}
          onRequestClose={() => {
            setStripeStatus((prev) => ({
              ...prev,
              isLoading: false,
              isUserStripeValidated: true,
              isConfirmToValidate: false,
            }));
          }}
        >
          <SafeAreaView style={styles.stripeModalContainer}>
            <WebView
              onLoad={onWebLoad}
              onLoadStart={onWebLoadStart}
              onNavigationStateChange={onNavigationStateChange}
              source={{ uri: stripeStatus.stripeUrl }}
              useWebView2
            />
          </SafeAreaView>
          {stripeStatus.isLoading && (
            <ActivityIndicator
              style={styles.loaderIndicator}
              color={colors.app.e}
              size="large"
            />
          )}
        </Modal>
      )}
      {showResponseModal.visible && (
        <ResponseModal
          visible={showResponseModal.visible}
          onRequestClose={showResponseModal.onRequestClose}
          bodyText={showResponseModal.bodyText}
          dismissText={showResponseModal?.dismissText}
        />
      )}
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  stripeModalContainer: {
    flex: 1,
  },
  loaderIndicator: {
    position: "absolute",
    alignSelf: "center",
    top: 0,
    bottom: 0,
    marginBottom: 14,
  },
});
