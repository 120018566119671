import { View } from "react-native";
import React from "react";
import { Font } from "../../font";

const NumericBadge = ({ count, top, right }) => {
  if (!count) {
    return <></>;
  }
  return (
    <>
      <View
        className={`bg-app-a border border-app-e rounded-full h-[18px] min-w-[18px] p-[.5px] justify-center items-center absolute ${
          top ? "top-" + top : "top-1"
        } ${right ? "right-" + right : "right-1"} flex lg:hidden`}
      >
        <Font
          className={`text-app-e text-xs tracking-wider leading-[15px] ${
            +count < 100 ? "w-auto" : "w-full"
          }`}
          weight={600}
        >
          {+count < 100 ? count : "99+"}
        </Font>
      </View>
      <View
        className={`bg-app-e border border-app-a rounded-full h-[18px] min-w-[18px] p-[.5px] justify-center items-center absolute top-1 right-1 hidden lg:flex`}
      >
        <Font
          className={`text-app-a text-xs tracking-wider leading-4 ${
            +count < 100 ? "w-auto" : "w-full"
          }`}
          weight={600}
        >
          {+count < 100 ? count : "99+"}
        </Font>
      </View>
    </>
  );
};

export default NumericBadge;
